import React, { Component } from 'react';
import BorderBottom from '../components/BorderBottom';
import CircleAnimation from '../components/CircleAnimation';
import sfondo from '../images/sfondo-contacts.jpg';
import Social from '../components/Social';

class Contacts extends Component {
    render() {
        return (
            <React.Fragment>
                <main id="main" className="site-main contactspage" role="main" style={{ backgroundImage: `url(${sfondo})` }}>

                    <div className="container">
                        <div className="row">
                            <CircleAnimation />
                            <div className="col-md-6">
                                <h1>Hey, would you like to contact me?</h1>
                                <p>Send me an email at <a href="mailto:hello@marcocappa.com">hello@marcocappa.com</a> or add me on <a target="_blank" href="https://www.linkedin.com/in/marcocappa" rel="noopener noreferrer">LinkedIn</a>.</p>
                            </div>
                        </div>
                    </div>
                    <BorderBottom />
                </main>
                <section id="contact-section">
                    <div className="container">
                        <div className="row">
                            <div className="col text-center">
                                <h2>Or find me here:</h2>
                            </div>
                            <div className="col-md-12 mb-3 mt-1 text-center">
                                <Social />
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Contacts;
