import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Social from './Social';

class Footer extends Component {
    render() {
        return (
            <footer id="footer" className="site-footer font-small" role="contentinfo">

                <div className="container">
                    <div className="row text-center d-flex justify-content-center pt-5 mb-3">
                        <div className="col-md-2 mb-3">
                            <Link className="text-uppercase font-weight-bold fs15" to="/">Home</Link>
                        </div>
                        <div className="col-md-2 mb-3">
                            <Link className="text-uppercase font-weight-bold fs15" to="/about">About</Link>
                        </div>
                        {/* <div className="col-md-2 mb-3">
                            <Link className="text-uppercase font-weight-bold fs15" to="/works">Works</Link>
                        </div> */}
                        <div className="col-md-2 mb-3">
                            <Link className="text-uppercase font-weight-bold fs15" to="/contacts">Contacts</Link>
                        </div>
                    </div>
                    <hr className="rgba-white-light" />
                    <div className="row d-flex text-center justify-content-center mb-md-0 mb-4">
                        <div className="col-md-8 col-12 mt-5">
                            <p className="quote">Have the courage to follow your heart and intuition. <br />They somehow already know what you truly want to become. Everything else is secondary.<br /><span>Steve Jobs</span>
                            </p>

                        </div>
                    </div>
                    <hr className="clearfix d-md-none rgba-white-light" />
                    <div className="row pb-3">
                        <div className="col-md-12 mb-3 mt-5 text-center">
                            <Social />
                        </div>
                    </div>
                </div>
                <div className="footer-copyright text-center py-3">© 2018 Copyright: <span style={{ color: '#2964b3' }}>Marco Cappa</span>
                </div>

            </footer>

        );
    }
}

export default Footer;
