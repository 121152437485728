import React, { Component } from 'react';
import BorderBottom from '../components/BorderBottom';
import CircleAnimation from '../components/CircleAnimation';
import sfondo from '../images/sfondo-works.jpg';

class Works extends Component {
    render() {
        return (
            <React.Fragment>
                <main id="main" className="site-main works" role="main" style={{ backgroundImage: `url(${sfondo})` }}>

                    <div className="container">
                        <div className="row">
                            <CircleAnimation />
                            <div className="col-md-6">
                                <h1>Some of my works.</h1>
                                <p>In the last few years I have been working on different projects, many of which in collaboration with <a target="_blank" href="https://www.up3up.it/" rel="noopener noreferrer">Up&amp;Up web agency</a>.</p>
                            </div>
                        </div>
                    </div>
                    <BorderBottom />
                </main>
                <section id="works-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Works;
