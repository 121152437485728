import React, { Component } from 'react';
import { Link } from "react-router-dom";
import BorderBottom from '../components/BorderBottom';
import CircleAnimation from '../components/CircleAnimation';
import sfondo from '../images/sfondo-home.jpg';

class Error404 extends Component {
    render() {
        return (
            <main id="main" className="site-main homepage" role="main" style={{ backgroundImage: `url(${sfondo})` }}>
                <div className="container">
                    <div className="row">
                        <CircleAnimation />
                        <div className="col-md-6">
                            <h1>Oops!</h1>
                            <p>Sorry I could not find the page but no problem you can easily <Link to="/">go back to homepage!</Link></p>
                        </div>
                    </div>
                </div>
                <BorderBottom />
            </main>
        );
    }
}

export default Error404;
