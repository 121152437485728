import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Header extends Component {

    render() {
        return (
            <header id="header" role="banner" className="bg-blue" >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <Link className="navbar-brand" to="/">Marco Cappa</Link>

                                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navContent" aria-controls="navContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button> */}

                                <div className="collapse navbar-collapse" id="navContent">
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/about">about</Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to="/works">works</Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contacts">contacts</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
