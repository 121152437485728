import React, { Component } from 'react';

class CircleAnimation extends Component {
    render() {
        return (
            <div className="circle-wrap">
                <div className="circle" style={{ animationDelay: '0s' }}></div>
                <div className="circle" style={{ animationDelay: '1s' }}></div>
                <div className="circle" style={{ animationDelay: '2s' }}></div>
                <div className="circle" style={{ animationDelay: '3s' }}></div>
                <div className="circle" style={{ animationDelay: '4s' }}></div>
            </div>
        );
    }
}

export default CircleAnimation;
