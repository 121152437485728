import React, { Component } from "react";
import BorderBottom from "../components/BorderBottom";
import CircleAnimation from "../components/CircleAnimation";
import sfondo from "../images/sfondo-about.jpg";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <main
          id="main"
          className="site-main homepage"
          role="main"
          style={{ backgroundImage: `url(${sfondo})` }}
        >
          <div className="container">
            <div className="row">
              <CircleAnimation />
              <div className="col-md-6">
                <h1>About me.</h1>
                <p>More about my experiences and passions.</p>
              </div>
            </div>
          </div>
          <BorderBottom />
        </main>
        <section id="next-section">
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <h2>Experience:</h2>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h4>Brilliant Basics</h4>
                <div className="date">
                  <p>jul 2019 - now</p>
                </div>
                <div className="website">
                  <p>
                    website:{" "}
                    <a
                      className="company-link"
                      href="https://www.brilliantbasics.com/"
                      alt="Brilliant Basics"
                      target="_blank"
                    >
                      Brilliant Basics
                    </a>
                  </p>
                </div>
                <p>
                  Brilliant Basics is a Design & Innovation Studio. We believe
                  in the power of design to make life simply better.
                </p>
                <p>Summary:</p>
                <ul>
                  <li>
                    Building B2B web applications in an Agile team using React,
                    Redux, Jest and enzyme;
                  </li>
                </ul>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h4>Procurement Leaders</h4>
                <div className="date">
                  <p>nov 2018 - jul 2019</p>
                </div>
                <div className="website">
                  <p>
                    website:
                    <a
                      className="company-link"
                      href="https://www.procurementleaders.com/"
                      alt="Procurement Leaders"
                      target="_blank"
                    >
                      Procurement Leaders
                    </a>
                  </p>
                </div>
                <p>
                  Procurement Leaders is the transformation partner our members
                  trust to accelerate their journey to superior performance,
                  turning procurement into a critical business driver.
                </p>
                <p>Summary:</p>
                <ul>
                  <li>
                    Worked in an Agile team (Different POs, scrum master, two
                    weeks sprints);
                  </li>
                  <li>
                    Design and addig new features to Data-hub application (React
                    survey app), Event-hub app and company website.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h4>Up&amp;Up Web Agency</h4>
                <div className="date">
                  <p>nov 2014 - oct 2018</p>
                </div>
                <div className="website">
                  <p>
                    website:
                    <a
                      className="company-link"
                      href="https://www.up3up.it"
                      alt="Up & Up - Web Agency"
                      target="_blank"
                    >
                      Up&amp;Up
                    </a>
                  </p>
                </div>
                <p>
                  Up&amp;Up is a web agency that offers a complete range of
                  services such as strategic marketing, visual identity,
                  developing website and e-commerce, web marketing and press
                  office.
                </p>
                <p>
                  In the last years{" "}
                  <strong>I have been working at Up&amp;Up</strong> and I made
                  experience in various projects, mainly as a frontend
                  developer.
                </p>
                <p>Tasks:</p>
                <ul>
                  <li>
                    Worked closely with designers and the content team to update
                    pages of each website using <strong>HTML</strong>,{" "}
                    <strong>CSS</strong>, <strong>PHP</strong> and{" "}
                    <strong>JavaScript</strong>;
                  </li>
                  <li>
                    Research and Development of ideas to{" "}
                    <strong>increase the engagement</strong> level on the site;
                  </li>
                  <li>
                    <strong>Developing Landing Pages</strong> from scratch;
                  </li>
                  <li>
                    Improve the performance of page speed and restructure code{" "}
                    <strong>working closely with SEO Dept.</strong>;
                  </li>
                  <li>
                    Ensuring all email campaigns comply with current email best
                    practice in the industry;
                  </li>
                  <li>
                    <strong>Tracking and reporting</strong> on email campaigns
                    in order to guide improvements;
                  </li>
                  <li>
                    Create promotional &amp; branding material for DEM,
                    newsletter, slides;
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10">
                <h4>Creative Studio Fàntasia</h4>
                <div className="date">
                  <p>sep 2013 - nov 2014</p>
                </div>
                <p>
                  Studio Fàntasia is a creative studio that helps client to
                  create a visual identity and communicate with customers.
                </p>
                <p>
                  I started my first IT experience{" "}
                  <strong>retouching images</strong> and{" "}
                  <strong>updating content</strong> of websites.
                </p>
                <p>Tasks:</p>
                <ul>
                  <li>
                    <strong>Data Entry</strong> on <strong>Wordpress</strong>{" "}
                    and Drupal websites;
                  </li>
                  <li>
                    Graphics: <strong>Use of Photoshop</strong> to apply effect
                    on images to upload into websites;
                  </li>
                  <li>
                    <strong>Create Reports</strong> with Google Analytics;
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section id="studies">
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <h2>Studies:</h2>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h5>EF - Education First: General English Course.</h5>
                <div className="date">
                  <p>mar 2017 - apr 2017</p>
                </div>
                <p>
                  I went to San Francisco one month to have an experience and
                  study English.
                </p>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h5>International School of Comics: Web Design course.</h5>
                <div className="date">
                  <p>sep 2013 - jul 2014</p>
                </div>
                <p>
                  HTML5, CSS, Responsive Web Design with custom media query,
                  Bootstrap, JavaScript, jQuery.
                </p>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h5>University of Brescia: Degree in Banking and Finance.</h5>
                <div className="date">
                  <p>sep 2008 - dec 2012</p>
                </div>
                <p>I gradued in 2012 in Finance.</p>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h5>University of Valencia: Erasmus Program.</h5>
                <div className="date">
                  <p>sep 2009 - jul 2010</p>
                </div>
                <p>I participated in the Erasmus program one year in Spain.</p>
              </div>
            </div>
            <div className="row p-bottom">
              <div className="col-md-10">
                <h5>IT Technical Institute.</h5>
                <div className="date">
                  <p>1999 - 2004</p>
                </div>
                <p>
                  I gradued in 2004 at the Giacomo Perlasca technical institute.
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
