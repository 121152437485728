import React, { Component } from 'react';
import bordo from '../images/border-bottom.png';

class BorderBottom extends Component {
    render() {
        return (
            <div
                className="border-bottom"
                style={{ backgroundImage: `url(${bordo})` }} ></div>
        );
    }
}

export default BorderBottom;
