import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

import Home from './pages/Home';
import About from './pages/About';
import Works from './pages/Works';
import Contacts from './pages/Contacts';
import Error404 from './pages/Error404';

import createBrowserHistory from 'history/createBrowserHistory';
const browserHistory = createBrowserHistory();

class App extends Component {

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
        <ScrollToTop>
          <Header />
          <div className="Route-content">
            <Switch>

              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/works" component={Works} />
              <Route path="/contacts" component={Contacts} />
              <Route component={Error404} />
            </Switch>

          </div>
          <Footer />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
