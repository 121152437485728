import React, { Component } from 'react';
import BorderBottom from '../components/BorderBottom';
import CircleAnimation from '../components/CircleAnimation';
import sfondo from '../images/sfondo-home.jpg';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <main id="main" className="site-main homepage" role="main" style={{ backgroundImage: `url(${sfondo})` }}>

                    <div className="container">
                        <div className="row">
                            <CircleAnimation />
                            <div className="col-md-6">
                                <h1>Hi there, <br /> I am Marco!</h1>
                                <p>I’m an organized and passionate Frontend Developer with constantly strive to learn, develop and grow with passion and energy.</p>
                            </div>
                        </div>
                    </div>
                    <BorderBottom />
                </main>
                <section id="about-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>About Me</h2>
                                <p>My background includes different technologies such as HTML5, CSS3, JavaScript, React, Redux.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Home;
