import React, { Component } from 'react';

import email from "../images/social/email.svg";
import linkedin from "../images/social/linkedin.svg";
import github from "../images/social/github.svg";
import facebook from "../images/social/facebook.svg";
import instagram from "../images/social/instagram.svg";

class Social extends Component {
    render() {
        return (
            <React.Fragment>
                <a className="social" href="mailto:hello@marcocappa.com" target="_blank" rel="noopener noreferrer">
                    <img src={email} alt="hello@marcocappa.com" />
                </a>
                <a className="social" target="_blank" href="https://www.linkedin.com/in/marcocappa/" rel="noopener noreferrer">
                    <img src={linkedin} alt="LinkedIn" />
                </a>
                <a className="social" target="_blank" href="https://github.com/marcocappa/" rel="noopener noreferrer">
                    <img src={github} alt="GitHub" />
                </a>
                <a className="social" target="_blank" href="https://www.facebook.com/marco.cappa.10" rel="noopener noreferrer">
                    <img src={facebook} alt="Facebook" />
                </a>
                <a className="social" target="_blank" href="https://www.instagram.com/markic/" rel="noopener noreferrer">
                    <img src={instagram} alt="Instagram" />
                </a>
            </React.Fragment>
        );
    }
}

export default Social;
